import React, { useEffect, useState } from "react";
import "./style.css";
import { Button, DatePicker, Input, InputNumber, Select, notification } from "antd";
import {
  COURSES_LIST,
  TRANSFERRED_COURSE_ADD,
  TRANSFERRED_COURSE_DELETE,
  TRANSFERRED_COURSE_LIST,
  TRANSFERRED_COURSE_UPDATE,
} from "../../../apis/apis";
import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined, SmileOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import SisLoader from "../../../widgets/loader";
export default function SingleTransferredCourse(props) {
  const {
    acad_calender_id,
    student_id,
    semester_id,
    sem_reg_id,
    set_sem_reg_id,
    set_transferred_Course,
    transferred_course,
    set_loader_change,
    set_main_page_loader,
    set_loader,
  } = props;
  const [page_loader, set_page_loader] = useState(true)
  const dateFormat = 'MM-DD-YYYY';
  const [courses, set_courses] = useState([]);
  const [table_list_2, set_table_list_2] = useState([]);
  const [course_id, set_course_id] = useState('');
  const [credit, set_credit] = useState(0);
  const [transferred_institution, set_transferred_institution] = useState('');
  const [api_transferred_course, set_api_transferred_course] = useState('');
  const [transferred_semester, set_transferred_semester] = useState('');
  const [begin_date, set_begin_date] = useState('');
  const [end_date, set_end_date] = useState('');
  const [credit_attempted, set_credit_attempted] = useState('');
  const [credit_earn, set_credit_earn] = useState('');

  const COURSES_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await COURSES_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      var filteredData = API_RESPONSE?.data?.courses.filter(
        (item) => item.soft_delete === 0
      );
      set_table_list_2(filteredData);
      set_loader(false);
      set_page_loader(false)
    } else {
      set_loader(false);
    }
  };
  const DELETE_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await TRANSFERRED_COURSE_DELETE(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Transfer course removed successfully!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(false);
      set_main_page_loader(true)
    } else {
      set_loader(false);
    }
  };
  const set_course_id_and_credit = (id) =>{
    
      const course_row = table_list_2.filter(row => row.id === parseInt(id));
     
      set_course_id(course_row[0].id);
      set_credit(course_row[0].units);
  }
  const UPDATE_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", transferred_course.id);
    FORM_DATA.append("acad_calender_id", transferred_course.acad_calender_id);
    FORM_DATA.append("semester_id", transferred_course.semester_id);
    FORM_DATA.append("student_id", transferred_course.student_id);
    FORM_DATA.append("semester_registration_id", transferred_course.semester_registration_id);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("credit", credit);
    FORM_DATA.append("transferred_institution", transferred_institution);
    FORM_DATA.append("transferred_semester", transferred_semester);
    FORM_DATA.append("transferred_course", api_transferred_course);
    FORM_DATA.append("begin_date", begin_date);
    FORM_DATA.append("end_date", end_date);
    FORM_DATA.append("credit_attempted", credit_attempted);
    FORM_DATA.append("credit_earn", credit_earn);
    const API_RESPONSE = await TRANSFERRED_COURSE_UPDATE(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Transfer course updated successfully !",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });

      set_loader(false);
      set_main_page_loader(true)
    } else {
      set_loader(false);
    }
  };
  useEffect(() => {
    COURSES_LIST_API();
  }, []);
  useEffect(() => {
     set_course_id(transferred_course.course_id);
     set_api_transferred_course(transferred_course.transferred_course);
     set_credit(transferred_course.credit);
     set_begin_date(transferred_course.begin_date);
     set_transferred_institution(transferred_course.transferred_institution);
     set_transferred_semester(transferred_course.transferred_semester);
     set_credit_attempted(transferred_course.credit_attempted)
     set_end_date(transferred_course.end_date);
     set_credit_earn(transferred_course.credit_earn);
     
  }, []);
  
console.log('transferred_course', course_id)
  
  return (
    <>
    {page_loader ? <SisLoader /> : <>
      <div className="TransferredCourse-row">
        <div className="input-box" style={{ maxWidth:"150px"}} >
          <label>LU Course</label>
          <Select
            placeholder="Select Courses"
            showSearch={true}
            style={{ width: "100%",  height:"40px" }}
          
            filterOption={(inputValue, option) => {
              if(!option.code.toLowerCase().indexOf(inputValue.toLowerCase())){
                return !option.code.toLowerCase().indexOf(inputValue.toLowerCase())
            }else{
                return !option.title.toLowerCase().indexOf(inputValue.toLowerCase())
            }
            }}
            onChange={(value) => set_course_id_and_credit(value)}
            value={course_id}
            options={table_list_2.map((item) => ({
              value: item.id,
              title: item.title,
              label: item.title + "(" + item.code + ")",
              code: item.code
            }))}
          ></Select>
        </div>
        <div className="input-box" style={{ maxWidth:"50px" }}>
          <label>Credit</label>
          <Input style={{ maxWidth:"50px",fontSize: "10px"}} value={credit} disabled/>
        </div>
        <div className="input-box" style={{ maxWidth:"100px" }}>
          <label>Transferred Course</label>
          <Input value={api_transferred_course} onChange={(e) => set_api_transferred_course(e.target.value)} style={{ maxWidth:"100px" , fontSize:"10px" }} />
        </div>
        <div className="input-box" style={{ maxWidth:"100px" }}>
          <label>Transferred institute</label>
          <Input  value={transferred_institution} onChange={(e) => set_transferred_institution(e.target.value)} style={{ maxWidth:"100px",fontSize: "10px" }} />
        </div>
        <div className="input-box" style={{ maxWidth:"100px" }}>
          <label>Transferred Semester</label>
          <Input  value={transferred_semester} onChange={(e) => set_transferred_semester(e.target.value)} style={{ maxWidth:"100px",fontSize: "10px" }} />
        </div>
        <div className="input-box" style={{ maxWidth:"115px"}}>
          <label>Begin Date</label>
          <DatePicker style={{ maxWidth:"115px" ,  height:"35px" , fontSize:"10px"}} format={dateFormat}  value={begin_date && dayjs(begin_date)} onChange={(date, dateString) => set_begin_date(dateString)}  />
        </div>
        <div className="input-box" style={{ maxWidth:"115px"}}>
          <label>End Date</label>
          <DatePicker style={{ maxWidth:"115px" , height:"35px" , fontSize:"10px" }} format={dateFormat} value={end_date && dayjs(end_date)} onChange={(date, dateString) => set_end_date(dateString)} />
        </div>
        <div  style={{ maxWidth:"60px"}} className="input-box">
          <label style={{fontSize:"9px"}}>Credit Attempted</label>
         <InputNumber  style={{ maxWidth:"60px" , height:"35px", fontSize:"12px" }} value={credit_attempted} min={1} max={credit > 0 ? credit : 1}  onChange={(value) => set_credit_attempted(value)} />
        </div>
        <div  style={{ maxWidth:"50px"}} className="input-box">
          <label style={{fontSize:"9px"}}>Credit Earned</label>
          <InputNumber  style={{ maxWidth:"50px", height:"35px", fontSize:"12px"}}  value={credit_earn} min={1} max={credit > 0 ? credit : 1}  onChange={(value) => set_credit_earn(value)} />
        </div>
        <div className="input-box" style={{ minWidth:"95px" }}>
          <label htmlFor="" style={{ color: "#f5f4f4" }}>
            Action buttons
          </label><br></br>
          <div>
          <Button type="primary" size="small" style={{fontSize:"10px", marginRight:"5px"}}  onClick={UPDATE_API}>UPDATE</Button>
          <Button type="primary" size="small" style={{fontSize:"10px"}}  onClick={() => DELETE_API(transferred_course.id)} danger>
          <DeleteOutlined
          />
          </Button>
          </div>
        </div>
      </div>
      </>}
    </>
  );
}
