
import React, { useEffect, useState } from 'react';
import { Table, Input, Avatar, Button } from 'antd';
import { UpOutlined, DownOutlined, EyeFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ADMIN_STUDENTS_LISTING, SIS_STORAGE } from '../../apis/apis';
import SisLoader from '../../widgets/loader';
const StudentsRequestsList = () => {

    const navigate = useNavigate();

    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    // Form State
    const [table_list, set_table_list] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);


    // DB list
    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await ADMIN_STUDENTS_LISTING(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_table_list(API_RESPONSE.data.enrollments_students);
            setFilteredData(API_RESPONSE.data.enrollments_students);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }


    useEffect(() => {
        LIST_API();
    }, [loader]);


    // Search
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        const filtered = table_list.filter(
            (item) => {
                var name = item.enrollment_middle_name === 'null' && !item.enrollment_middle_name ? item.enrollment_first_name + ' ' + item.enrollment_last_name : item.enrollment_first_name + ' ' + item.enrollment_middle_name + ' ' + item.enrollment_last_name;
                if (name.toLowerCase().includes(query.toLowerCase()) || item.programme_title.toLowerCase().includes(query.toLowerCase())) {
                    return item;
                }
            }
        );
        if (query === "") {
            setFilteredData(table_list);
        } else {
            setFilteredData(filtered);
        }
    };

    const students_requests_list_column = [
        
        {
            title: "Name",
            width:"22%",
            render: (text, record) => {
                return <><span>Business Principles</span></>;
            },
            sorter: (a, b) => a.transferred_course.localeCompare(b.transferred_course),
        },
        {
            title: "Course Name",
            width:"33%",
            render: (text, record) => {
                return <><span>{record?.course_code}</span></>;
            },
            sorter: (a, b) => a.transferred_course.localeCompare(b.transferred_course),
        },
        {
            title: "Request Type",
            width:"15%",
            render: (text, record) => {
                return <><span>{record?.course_code}</span></>;
            },
        },
        {
            title: "Request Status",
            width:"15%",
            render: (text, record) => {
                return <><span>{record?.course_code}</span></>;
            },
        },
        {
            title: "Request Date",
            width:"15%",
            render: (text, record) => {
                return <><span>{record?.course_code}</span></>;
            },
        },
       
    ];

    return (
        <div>
            <div className="theme-content-head">
                <div className="theme-content-left-head">
                    <h3>Student Requests</h3>
                </div>
            </div>

            <div className="theme-content-head">
                <div className="input-box">
                    <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => handleSearch(e)}
                    />
                    {errors?.code && (
                        <span style={{ color: "red" }}>{errors?.code[0]}</span>
                    )}
                </div>
            </div>
            {loader ? <SisLoader /> : <Table columns={students_requests_list_column}/>}

        </div>
    );
}

export default StudentsRequestsList;




