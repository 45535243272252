import { useState } from "react";
import profileuser from "../../images/user.png"

const StudentRequestDetail = () => {
  const [image, set_image] = useState("");
  const [first_name, set_first_name] = useState("");
  const [middle_name, set_middle_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [student_id, set_student_id] = useState("");
  const [programme_intended, set_programme_intended] = useState("");
  const [application_no, set_application_no] = useState("");
  return (
    <>
      <div className="row">
        <div className="col-2">
          <div className="input-box" style={{ position: "relative" }}>
            <div className="image-box" style={{border:"none", width:"100px", height:"100px"}}>
              <img
                alt="student_image"
                src={profileuser}
                //   src={URL.createObjectURL(image)}
                frameborder="0"
                style={{ width: "100%", height: "100%" }}
              ></img>
            </div>
          </div>
        </div>
        <div className="col-2">
        <div className="input-box" style={{ position: "relative" }}>
            <h3>Ankit Kumar Sharma{first_name}{" "}{middle_name === null ? "" : middle_name}{" "}{last_name}</h3>
            </div>
        </div>
        <div className="col-2">
        <div className="input-box" style={{ position: "relative" }}>
        <p>Application Number</p>
        <h4 style={{ marginTop: "6px" }}>  
            {/* {personal_details?.application_no} */}
            </h4>
            </div>
        </div>
        <div className="col-2">
        <div className="input-box" style={{ position: "relative" }}>
        <p>Student ID</p>
        <h4 style={{ marginTop: "6px" }}>
             {/* {personal_details?.student_id} */}
             </h4>
             </div>
        </div>
        <div className="col-3">
        <div className="input-box" style={{ position: "relative" }}>
        <p>Programme/Degree</p>
        <h4 style={{ marginTop: "6px" }}> 
            {/* {personal_details?.student_id} */}
            </h4>
            </div>
        </div>
      </div>
    </>
  );
};

export default StudentRequestDetail;
