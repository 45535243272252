import { Input, Select } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ForOfficeUse from "./forOfficeUse";
import PersonalInformation from "./personalInformation";
import DemographicInformation from "./demographicInformation";
import FinanceDeclaration from "./financeDeclaration";
import Agreement from "./agreement";
import OnlineFeePayment from "./OnlineFeePayment";


const EditStudentOnlineApplication = () => {
  const navigate = useNavigate();

  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const { id } = useParams();
    return(
        <>
      <ForOfficeUse id={id}/>
      <PersonalInformation id={id}/>
      <DemographicInformation id={id}/>
      <FinanceDeclaration id={id}/>
      <Agreement id={id}/>
      <OnlineFeePayment id={id}/>
        </>
    );
}

export default EditStudentOnlineApplication;